<template>
<div>
        <loading :active.sync="loading"></loading>
        <!--<link rel="stylesheet" href="https://unpkg.com/@coreui/icons/css/all.min.css">-->
        <div class="row"><div class="col-md-12">
          <b-card style="max-width: 1050px;">
            <div slot="header">
            <div class="row">
              <div class="col-md-2">
                <strong>Produtos</strong>
              </div>
              <div class="col-md-10" style="text-align:right;">
                <b-button variant="link" size="sm" @click="prp(0)"><CIcon name="cil-list" />&nbsp;<small>PREPAROS...</small></b-button>
                <b-form-input size="xs" style="margin-left:10px;float:right;width:200px;" v-model="keyword" @keyup="procura()" placeholder="Buscar produtos..."></b-form-input>
                <a
                  class="btn btn-link" 
                  href="javascript:;"
                  @click="showModalSimples()" >
                  <CIcon name="cil-cloud-upload" />&nbsp;<small>Importar</small>
              </a>
                <download-excel
                  class="btn btn-link" 
                  :data   = "matExcel"
                  name = "produtos.xls"
                   >
                  <CIcon name="cil-cloud-download" />&nbsp;<small>Exportar</small>
              </download-excel>
                <b-button variant="link" size="sm" @click.stop="ajuda()"><CIcon name="cil-lightbulb" />&nbsp;<small>Ajuda</small></b-button>
              </div>
            </div>
          </div>
<b-tabs content-class="mt-3" v-model="ativa">
  <b-tab title="Ativos" active>
        <table class="table table-hover">
            <thead>
            <tr>
                <th style="width:3%"></th>
                <th style="width:12%">Linha</th>
                <th style="width:10%">Cód. Produto</th>
                <th style="width:30%">Nome do Produto</th>
                <th style="width:10%">Unid. de Venda</th>
                <th style="width:5%">Perda</th>
                
                <th style="width:20%" data-v-step="10"></th>
            </tr>
            </thead>

            <tbody>
                <tr v-for="(materialItem, index) in prd" :key="index">
                    <td data-title="Ativar?">
                      <b-form-checkbox
                        v-model="prd[index].prd_active" 
                        switch 
                        @change.native="editar(index)"
                      ></b-form-checkbox>
                    </td>
                    <td data-title="Linha">
                      <b-form-select class="form-control verde" @change="editar(index, true)" v-model="prd[index].pln_ID" :options="linhaProdutos"></b-form-select>
                    </td>
                    <td data-title="Cód. Produto">
                      <b-form-input class="verde" @blur="editar(index, true)" v-model="prd[index].prd_code"></b-form-input>
                    </td>
                    <td data-title="Nome do Produto">
                      <b-form-input class="verde" @blur="editar(index, true)" v-model="prd[index].prd_name"></b-form-input>
                    </td>
                    <td data-title="Unid. de Venda">
                      <b-form-input class="verde" @blur="editar(index, true)" v-model="prd[index].prd_descr"></b-form-input>
                    </td>
                    <td data-title="Perda">
                      <vue-autonumeric @blur.native="editar(index, true)" class="form-control verde centro" :options="percentual" v-model="prd[index].prd_perda"></vue-autonumeric>
                    </td>
                    <td data-title="Duplicar produto" style="text-align:center;">
                      <button class="btn btn-link" title="Duplicar produto" @click="dup(prd[index].prd_ID)">Duplicar...</button>
                    </td>
                </tr>
                <tr id="adicionando">
                    <td></td>
                    <td data-title="Linha" data-v-step="4">
                      <b-form-select class="form-control" v-model="prdLinha" aria-placeholder="Linha" :options="linhaProdutos"></b-form-select>
                    </td>
                    <td data-title="Cód. Produto"><b-form-input v-model="prdCode" placeholder="Cód. Produto" data-v-step="3"></b-form-input></td>
                    <td data-title="Nome do Produto"><b-form-input v-model="prdName" placeholder="Nome do Produto" data-v-step="2"></b-form-input></td>
                    <td data-title="Unid. de Venda"><b-form-input v-model="prdDescr" placeholder="Unid. de Venda" data-v-step="5"></b-form-input></td>
                    <td colspan="2" data-title="Criar produto"><button data-v-step="7" class="btn btn-success" :disabled="adicionou" @click="adicionar()">Adicionar</button></td>
                </tr>
                <tr style="margin-top:20px;">
                  <td colspan="6"><b-button style="margin-top:10px;" variant="primary" @click="voltar()"><CIcon name="cil-clone" /> Voltar para os Custos Diretos (Produtos)</b-button></td>
                </tr>
            </tbody>
        </table>
        </b-tab>
<b-tab title="Inativos">
  <table class="table table-hover">
            <thead>
            <tr>
                <th style="width:3%"></th>
                <th style="width:12%">Linha</th>
                <th style="width:10%">Cód. Produto</th>
                <th style="width:30%">Nome do Produto</th>
                <th style="width:10%">Unid. de Venda</th>
                <th style="width:5%">Perda</th>
                <th style="width:20%" data-v-step="10"></th>
            </tr>
            </thead>

            <tbody>
                <tr v-for="(materialItem2, index) in prdoff" :key="index">
                    <td data-title="Ativar?">
                      <b-form-checkbox
                        v-model="prdoff[index].prd_active" 
                        switch 
                        @change.native="reativarItem(prdoff[index].prd_ID)"
                        size="sm" 
                      ></b-form-checkbox>
                    </td>
                    <td data-title="Linha">
                      <b-form-select class="form-control verde" readonly v-model="prdoff[index].pln_ID" :options="linhaProdutos"></b-form-select>
                    </td>
                    <td data-title="Cód. Produto">
                      <b-form-input class="verde" readonly v-model="prdoff[index].prd_code"></b-form-input>
                    </td>
                    <td data-title="Nome do Produto">
                      <b-form-input class="verde" readonly v-model="prdoff[index].prd_name"></b-form-input>
                    </td>
                    <td data-title="Unid. de Venda">
                      <b-form-input class="verde" readonly v-model="prdoff[index].prd_descr"></b-form-input>
                    </td>
                    <td data-title="Perda">
                      <vue-autonumeric class="form-control amarelo centro" :options="percentual" v-model="prdoff[index].prd_perda" readonly></vue-autonumeric>
                    </td>
                    <td>
                      <b-button variant="danger" size="sm" @click="remocaoProduto(prdoff[index].prd_ID)">Remover</b-button>
                    </td>
                </tr>
                <tr style="margin-top:20px;">
                  <td colspan="6"><b-button style="margin-top:10px;" variant="primary" @click="voltar()"><CIcon name="cil-plus" /> Voltar para os Custos Diretos (Produtos)</b-button></td>
                </tr>
            </tbody>
        </table>
</b-tab>
</b-tabs>
          </b-card>

          

          <b-modal size="lg" ref="modal-simples" hide-footer title="Múltiplos produtos">
            <b-alert variant="info" show dismissible>
              <p>Utilize o modelo de planilha abaixo para subir mais de um produto de uma vez.<br>
              Você pode utilizar o EXCEL para copiar e colar aqui.<br>
              Atenção para a coluna Linha. Caso não exista, o mesmo será criado automaticamente.</p>
            </b-alert>
            <b-alert variant="danger" v-if="isMobile()" show>
        <p><strong>Desculpe!</strong> Este recurso no momento é incompatível com dispositivos móveis.</p>
      </b-alert>
            <hot-table ref="dataTables" :data="dataTable" :settings="dataTableSet"></hot-table>
            <div class="row">
              <div class="col-md-8">
                <b-button variant="success" :disabled="adicionou" @click="salvaSimplesData()">Salvar planilha</b-button>
                &nbsp;&nbsp;<b-button variant="danger" size="sm" @click="removeSimplesData()">Limpar</b-button>
              </div>
              <div class="col-md-4">
                <b-progress :value="vTbl" :max="maxTbl" show-progress animated></b-progress>
              </div>
            </div>
          </b-modal>

        </div></div>
        <b-modal size="lg" id="ajuda" hide-footer title="Produtos">
      <div>
      <b-card no-body>
        <b-tabs card>
          <b-tab no-body title="Tutorial" active>
            <b-card-text>
              <b-embed
              type="iframe"
              aspect="16by9"
              src="https://www.youtube.com/embed/OIuJ5wPC0zs?rel=0"
              allowfullscreen
            ></b-embed>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
      </div>
    </b-modal>
        <a href="#adicionando" class="btnfloat">
      <CIcon name="cil-plus" class="my-btnfloat" />
    </a>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import VueAutonumeric from 'vue-autonumeric'
import { HotTable } from '@handsontable/vue'

export default {
  data () {
    return {
      stepsOptions: {
        labels: {
          buttonSkip: 'Sair da ajuda',
          buttonPrevious: 'Anterior',
          buttonNext: 'Próximo',
          buttonStop: 'OK!'
        }
      },
      steps: [
        {
          target: '[data-v-step="1"]',
          header: {
            title: 'Cadastro de Produtos',
          },
          content: `Aqui você cadastra os produtos que irão compor o seu Gera Preço!`
        },
        {
          target: '[data-v-step="2"]',
          content: 'Para adicionar um novo produto, digite o nome do produto aqui.',
          params: {
            placement: 'top'
          }
        },
        {
          target: '[data-v-step="3"]',
          content: 'Coloque aqui o código interno usado para este produto.',
          params: {
            placement: 'top'
          }
        },
        {
          target: '[data-v-step="4"]',
          content: 'Recomendamos que você informe a linha na qual esse produto faz parte. Você pode cadastrar linhas no menu Configurações.',
          params: {
            placement: 'top'
          }
        },
        {
          target: '[data-v-step="5"]',
          content: 'Opcionalmente, digite aqui a embalagem desse produto. Exemplo: Frasco de 100ml.',
          params: {
            placement: 'top'
          }
        },
        {
          target: '[data-v-step="6"]',
          content: 'Escolha aqui se isso é um Produto ou um Preparo (que é quando você usa para fabricar outros produtos)',
          params: {
            placement: 'bottom'
          }
        },
        {
          target: '[data-v-step="7"]',
          content: 'Clique em adicionar e pronto!',
          params: {
            placement: 'bottom'
          }
        },
        {
          target: '[data-v-step="8"]',
          content: 'Depois de adicionado, você pode associar aqui os INSUMOS que compõe este produto (materiais, serviços de terceiros ou embalagens)',
          params: {
            placement: 'top'
          }
        },
        {
          target: '[data-v-step="9"]',
          content: 'Aqui você associa a mão de obra utilizada para a criação desse produto.',
          params: {
            placement: 'top'
          }
        },
        {
          target: '[data-v-step="10"]',
          content: 'Este botão serve para duplicar o produto, sem precisar de criar um novo e reassociar todos os insumos e mão de obra novamente.',
          params: {
            placement: 'top'
          }
        }
      ],
      linhaProdutos: [],
      matExcel: [],
      materialItem: [],
      maxProd: null,
      sessao: '',
      loading: false,
      prd: [],
      prdoff: [],
      ativa: 0,
      prdFull: [],
      prdName: '',
      prdDescr: '',
      prdCode: '',
      prdValor: 0,
      prdActive: 1,
      prdLinha: 0,
      prdTipo: 1,
      adicionou: false,
      dinheiro: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: this.$session.get('regras')[0].grp_moeda + ' ',
        currencySymbolPlacement: 'p',
        decimalPlaces: 2
      },
      dinheiro2: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: this.$session.get('regras')[0].grp_moeda + ' ',
        currencySymbolPlacement: 'p',
        decimalPlaces: 3
      },
      percentual: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: ' %',
        currencySymbolPlacement: 's',
        decimalPlaces: 1
      },
      decimal: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: '',
        decimalPlaces: 2
      },
      tiposProdutos: [
        {value: 1, text: 'Produto'}
        /* {value: 2, text: 'Preparo'} */
        /* {value: 3, text: 'Produto + Pré-Preparo'} */
      ],
      keyword: '',
      dataTable: [],
      opcoesLinha: [],
      opcoesLinhaIds: [],
      vTbl: 0,
      maxTbl: 0,
      dataTableSet: {
        colHeaders: ['Linha', 'Código', 'Nome do Produto', 'Unidade de Venda', 'Tipo'],
        rowHeaders: true,
        minSpareRows: 2,
        width: 800,
        height: 250,
        colWidths: [100, 100, 200, 100, 200],
        columns: [
          {
            type: 'autocomplete',
            source: this.opcoesLinha,
            strict: true,
            allowInvalid: true
          },
          {}, {}, {},
          {
            type: 'autocomplete',
            source: ['Produto', 'Preparo'],
            strict: true,
            allowInvalid: false
          }
        ],
        licenseKey: 'non-commercial-and-evaluation'
      }
    }
  },
  beforeCreate: function () {
    if (!this.$session.exists()) {
      this.$router.push('/login')
    }
  },
  created: function () {
    this.verificaSessao()
  },
  methods: {
    isMobile: function () {
      if (screen.width <= 760) {
        return true;
      }
      else {
        return false;
      }
    },
    verificaSessao: function () {
      if (!this.$session.exists()) { this.$session.start() }
      this.$http.get(this.URLApi + 'user/session', {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': this.ChaveApi
        }
      }).then((response) => {
        this.loading = true
        this.linhaProdutos = []
        this.opcoesLinha = []
        this.opcoesLinhaIds = []
        this.prd = []
        this.prdoff = []
        this.listar('pro_Produto_Linha', 'pln_name', 'pln_active = 1').then(
          (r) => {
            for (var i = 0; i < r.length; i++) {
              this.linhaProdutos.push({value: r[i].pln_ID, text: r[i].pln_name })
              this.opcoesLinha.push(r[i].pln_name)
              this.opcoesLinhaIds[r[i].pln_ID] = r[i].pln_name
            }
            this.dataTableSet.columns[0].source = this.opcoesLinha
            this.listar('pro_Produto', 'prd_name', '(prd_active = 0) AND (prd_tipo = 1)').then(
              (rrr) => {
                this.prdoff = rrr
              }
            )
            this.listar('pro_Produto', 'prd_name', '(prd_active = 1) AND (prd_tipo = 1)').then(
              (response) => {
                this.prd = response
                this.prdFull = this.prd
                // Buscando o total de produtos permitidos
                if (this.$session.get('grupo') > 0) {
                  this.campo('gp_Grupo', 'grp_registros', 'grp_ID = ' + this.$session.get('grupo')).then(
                    (g) => {
                      this.maxProd = g
                    },
                    (g) => {
                      this.maxProd = null
                    }
                  )
                }
                // Excel
                for (var z = 0; z < response.length; z++) {
                  this.matExcel.push({
                    Ativo: response[z].prd_active ? 'Sim' : 'Não',
                    Linha: (response[z].pln_ID ? this.opcoesLinhaIds[response[z].pln_ID] : ''),
                    Cod: response[z].prd_code,
                    Nome: response[z].prd_name,
                    Unid_Producao: response[z].prd_descr,
                    Perda: response[z].prd_perda.toLocaleString('pt-BR', {minimumFractionDigits: 3}) + '%',
                  })
                }
                this.loading = false
              }, (response) => {})
            },
            (r) => {}
        )
      }, (response) => {
        var m = response.body.error.code
        if (m === 401) {
          this.$notify({
            type: 'danger',
            title: 'Sessão expirada',
            text: 'Seu tempo de logado expirou.'
          })
        }
        this.$router.push('/login')
      })
    },
    adicionar: function () {
      if (this.prdCode === '' || this.prdName === '') {
        this.$swal('Todos os campos são obrigatórios')
        return false
      }
      this.adicionou = true
      // Verifica se já existe
      this.campo('pro_Produto', 'prd_ID', '(prd_code = ' + this.prdCode + ')').then(
        (response) => {
          if (typeof response !== 'undefined' && response === this.prdCode) {
            this.$swal('Já existe um registro com este codigo: ID ' + response + '')
          } else {
            this.$swal('Erro ao inserir: Já existe um produto/preparo com esse código. ' + JSON.stringify(response))
          }
          this.adicionou = false
        }, (response) => {
          if (this.maxProd > 0 && this.maxProd <= this.prd.length) {
            this.$swal('Você atingiu o limite máximo de produtos/preparos cadastrados para o seu plano.')
            return false;
          }
          this.loading = true
          this.inserir('pro_Produto', {resource: [ {prd_code: this.prdCode, pln_ID: this.prdLinha, prd_name: this.prdName, prd_descr: this.prdDescr, prd_perda: 0, prd_tipo: this.prdTipo, prd_valor: this.prdValor, prd_active: 1} ]}).then(
            (response) => {
              // Se for pre-preparo, cria o material associado ao produto
              if (this.prdTipo === 2 || this.prdTipo === 3) {
                var a = response
                this.inserir('pro_Material', {resource: [{ prd_ID: a[Object.keys(a)[0]], mat_tipo_ID: 1, mat_name: this.prdName }]}, '', '1')
              }
              this.prdName = ''
              this.prdCode = ''
              this.prdValor = 0
              this.prdLinha = 0
              this.verificaSessao()
              this.adicionou = false
            },
            (response) => {
              this.$swal('Erro ao inserir: ' + JSON.stringify(response))
              this.loading = false
              this.adicionou = false
            }
          )
        }
      )
    },
    editar: function (index, fast) {
      if (this.prd[index].prd_code === '' || this.prd[index].prd_name === '') {
        this.$swal('Todos os campos são obrigatórios')
        return false
      }
      // Verifica se já existe
      this.campo('pro_Produto', 'prd_ID', '(prd_code = ' + this.prd[index].prd_code + ') AND (prd_name = ' + this.prd[index].prd_name + ') AND (prd_ID != ' + this.prd[index].prd_ID + ')').then(
        (response) => {
          if (typeof response !== 'undefined' && response === this.prdCode) {
            this.$swal('Já existe um registro com este codigo: ID ' + response + '')
          } else {
            this.$swal('Erro ao atualizar: Produto/preparo com mesmo nome e código já existe.')
          }
        }, (response) => {
          this.loading = true
          this.atualizar('pro_Produto', {resource: [ {prd_code: this.prd[index].prd_code, pln_ID: this.prd[index].pln_ID, prd_name: this.prd[index].prd_name, prd_descr: this.prd[index].prd_descr, prd_perda: this.prd[index].prd_perda, prd_tipo: this.prd[index].prd_tipo, prd_valor: this.prd[index].prd_valor, prd_active: this.prd[index].prd_active} ]}, 'prd_ID = ' + this.prd[index].prd_ID).then(
            (response) => {
              // Ao editar, cria ou modifica o material associado ao produto (pré-preparo)
              if ((this.prd[index].prd_tipo === 2 || this.prd[index].prd_tipo === 3) && parseInt(this.prd[index].active) === 1) {
                // Verifica se existe
                this.campo('pro_Material', 'mat_ID', '(prd_ID = ' + this.prd[index].prd_ID + ')').then(
                  (t) => {
                    if (t > 0) {
                      // ja tem
                      this.atualizar('pro_Material', {resource: [{ mat_name: this.prd[index].prd_name }]}, 'mat_ID = ' + t, '', '1')
                    } else {
                      // cria
                      this.inserir('pro_Material', {resource: [{ prd_ID: this.prd[index].prd_ID, mat_tipo_ID: 1, mat_name: this.prd[index].prd_name }]}, '', '1')
                    }
                  },
                  (t) => {
                    // cria
                    this.inserir('pro_Material', {resource: [{ prd_ID: this.prd[index].prd_ID, mat_tipo_ID: 1, mat_name: this.prd[index].prd_name }]}, '', '1')
                  }
                )
              } else {
                this.remover('pro_Material', '(prd_ID = ' + this.prd[index].prd_ID + ')')
              }
              if (fast) {
                this.loading = false
              } else {
                this.verificaSessao()
              }
            },
            (response) => {
              this.$swal('Erro ao atualizar: ' + JSON.stringify(response))
              this.loading = false
            }
          )
        }
      )
    },
    recarregar: function () {
      this.verificaSessao()
    },
    procura: function () {
      this.prd = this.prdFull
      this.prd = (this.keyword
				? this.prd.filter(item => item.prd_code.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(this.keyword.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) || String(item.prd_name).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(this.keyword.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) || String(item.prd_descr).toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(this.keyword.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")))
				: this.prd)
    },
    salvaSimplesData: function () {
      this.adicionou = true
      this.maxTbl = 0
      this.vTbl = 0
      // Analisando os dados
      if (this.dataTable.length === 0) {
        alert('Tabela vazia')
        this.adicionou = false
        return false
      }
      var prom = []
      var erros = ''
      for (var i = 0; i < this.dataTable.length; i++) {
        if (typeof this.dataTable[i][2] !== 'undefined' && this.dataTable[i][2] !== '' && this.dataTable[i][2] !== null && this.dataTable[i][4] !== null && this.dataTable[i][4] !== '') {
          this.maxTbl++
        }
      }
      if (erros || this.maxTbl === 0) {
        this.$swal('Atenção', 'Tabela vazia?<br>' + erros, 'error')
        this.adicionou = false
        return false
      } else {
        // Adiciona
        for (var i = 0; i < this.dataTable.length; i++) {
          if (typeof this.dataTable[i][2] !== 'undefined' && this.dataTable[i][2] !== '' && this.dataTable[i][2] !== null && this.dataTable[i][4] !== null && this.dataTable[i][4] !== '') {
            prom.push(this.adicionarMassa(this.dataTable[i]))
          }
        }
        Promise.all(prom).then(
          (r) => {
            console.log(r)
            for (var v = 0; v < r.length; v++) {
              if (r[v] === 0) {
                erros += 'Linha ' + (v+1) + ' não foi adicionada<br>'
              } else {
                this.vTbl++
              }
            }
            if (this.vTbl !== this.maxTbl) {
              this.$swal('Importação finalizada!', 'Alguns itens não foram adicionados pois provavelmente já existiam:<div style="overflow:auto;height:100px;"><small>' + erros + '</small></div>', 'warning')
              this.adicionou = false
              this.recarregar()
              return false
            } else {
              this.$notify({
                type: 'success',
                title: 'Importação Finalizada',
                text: 'Todos os itens foram adicionados'
              })
              this.adicionou = false
              this.dataTable = []
              this.recarregar()
              return false
            }
          },
          (r) => {
            console.log(r)
            alert('Erro ao processar planilha')
            this.adicionou = false
            return false
          }
        )
      }
    },
    showModalSimples: function () {
      this.$refs['modal-simples'].show()
    },
    removeSimplesData: function () {
      this.dataTable = []
      this.vTbl = 0
    },
    uso: function (p) {
      this.$router.push('/tabelas/produtosmateriais/' + p)
    },
    mo: function (p) {
      this.$router.push('/tabelas/maodeobrauso/' + p)
    },
    voltar: function () {
      this.$router.push("/precificador")
    },
    adicionarMassa: function (p) {
      return this.campo('pro_Produto', 'prd_ID', '(prd_name = ' + p[2] + ')').then(
        (response) => {
          if (typeof response !== 'undefined') {
            return 0
          } else {
            return 0
          }
        }, (response) => {
          if (p[2] === '' || p[4] === '') {
            return 0
          }
          if (p[0] === '' || p[0] === null) {
            p[0] = 'Padrão'
          }
          // Traduz o tipo
          if (p[4] === 'Produto') {
            p[4] = 1
          } else if (p[4] === 'Preparo') {
            p[4] = 2
          } else {
            p[4] = 1
          }
          // Verifica se a linha existe
          var prod = {}
          return this.campo('pro_Produto_Linha', 'pln_ID', '(pln_name = ' + p[0] + ')').then(
            (pln) => {
              // Existe
              if (pln > 0) {
                prod = {resource: [ {pln_ID: pln, prd_tipo: p[4], prd_name: p[2], prd_code: p[1], prd_descr: p[3], prd_active: 1} ]}
                return this.inserir('pro_Produto', prod, '', '1').then(
                  (r) => {
                    // Se for pre-preparo, cria o material associado ao produto
                    if (p[4] === 2 || p[4] === 3) {
                      this.inserir('pro_Material', {resource: [{ prd_ID: r[Object.keys(r)[0]], mat_tipo_ID: 1, mat_name: p[2] }]}, '', '1')
                    }
                    return 1
                  },
                  (r) => {
                    return 0
                  }
                )
              } else {
                console.log('Falha na busca do pln?')
              }
            },
            (pln) => {
              // Nao existe
              return this.inserir('pro_Produto_Linha', {resource: [{ pln_name: p[0], pln_active: 1 }]}, '', '1').then(
                (plnn) => {
                  prod = {resource: [ {pln_ID: plnn[Object.keys(plnn)[0]], prd_tipo: p[4], prd_name: p[2], prd_code: p[1], prd_descr: p[3], prd_active: 1} ]}
                  return this.inserir('pro_Produto', prod, '', '1').then(
                    (r) => {
                      // Se for pre-preparo, cria o material associado ao produto
                      if (p[4] === 2 || p[4] === 3) {
                        this.inserir('pro_Material', {resource: [{ prd_ID: r[Object.keys(r)[0]], mat_tipo_ID: 1, mat_name: p[2] }]}, '', '1')
                      }
                      return 1
                    },
                    (r) => {
                      return 0
                    }
                  )
                },
                (plnn) => {
                  console.log('Falha ao inserir?')
                  return 0
                }
              )
            }
          )
        }
      )
    },
    reativarItem: function (i) {
      this.atualizar('pro_Produto', {resource: [ {prd_active: 1 } ]}, 'prd_ID = ' + String(i)).then(
        (r) => {
          this.ativa = 0
          this.verificaSessao()
        },
        (r) => {
          this.verificaSessao()
        }
      ) 
    },
    p: function (index) {
      this.$router.push('/configuracoes/produtos')
    },
    prp: function (index) {
      this.$router.push('/configuracoes/preparos')
    },
    dup: function (p) {
      this.loading = true
      this.campos('pro_Produto','prd_ID = ' + p).then(
        (pr) => {
          delete pr.prd_ID
          pr.prd_name = pr.prd_name + ' (Cópia)'
          if (this.maxProd > 0 && this.maxProd <= this.prd.length) {
            this.$swal('Você atingiu o limite máximo de produtos cadastrados para o seu plano.')
            return false;
          }
          this.inserir('pro_Produto', {resource: [ pr ]}, null, '1').then(
            (np) => {
              // Se for pré-preparo, tem que duplicar o registro em materiais
              var novoprod = np[Object.keys(np)[0]]
              if (pr.prd_tipo === 2 || pr.prd_tipo === 3) {
                var pre = {
                  prd_ID: novoprod,
                  mat_tipo_ID: 1,
                  mat_name: pr.prd_name,
                  mat_value: 0,
                  mat_total: 0,
                  mat_active: 1
                }
                this.inserir('pro_Material', {resource: [ pre ]}, null, '1')
              }
              this.listar('pro_Produto_Material', 'pmt_ID', 'prd_ID = ' + p).then(
                (pm) => {
                  var prom = []
                  for (var i = 0; i < pm.length; i++) {
                    delete pm[i].pmt_ID
                    pm[i].prd_ID = novoprod
                    prom.push(this.inserir('pro_Produto_Material', {resource: [ pm[i] ]}, null, '1'))
                  }
                  this.listar('pro_Produto_Maodeobra', 'pmo_ID', 'prd_ID = ' + p).then(
                    (po) => {
                      var prom2 = []
                      for (var i = 0; i < po.length; i++) {
                        delete po[i].pmo_ID
                        po[i].prd_ID = novoprod
                        prom2.push(this.inserir('pro_Produto_Maodeobra', {resource: [ po[i] ]}, null, '1'))
                      }
                      Promise.all(prom, prom2).then(
                        (resp) => {
                          // Verifica se é um produto de pré-preparo e atualiza o valor
                          this.campo('pro_Material', 'mat_ID', '(prd_ID = ' + novoprod + ')').then(
                            (x) => {
                              if (x > 0) {
                                // Pega o total de material e insumos
                                this.campo('filtro_Produto', 'prd_custodireto_total', '(prd_ID = ' + novoprod + ')').then(
                                  (r) => {
                                    // Atualiza no pro_Material
                                    this.atualizar('pro_Material', {resource: [ {mat_total: r, mat_value: r} ]}, '(mat_ID = ' + x + ')', '', '1')
                                    this.verificaSessao()
                                    this.loading = false
                                  },
                                  (r) => {
                                    this.verificaSessao()
                                    this.loading = false
                                  }
                                )
                              }
                            },
                            (x) => {
                              this.verificaSessao()
                              this.loading = false
                            }
                          )
                        },
                        (resp) => {
                          // Verifica se é um produto de pré-preparo e atualiza o valor
                          this.campo('pro_Material', 'mat_ID', '(prd_ID = ' + novoprod + ')').then(
                            (x) => {
                              if (x > 0) {
                                // Pega o total de material e insumos
                                this.campo('filtro_Produto', 'prd_custodireto_total', '(prd_ID = ' + novoprod + ')').then(
                                  (r) => {
                                    // Atualiza no pro_Material
                                    this.atualizar('pro_Material', {resource: [ {mat_total: r, mat_value: r} ]}, '(mat_ID = ' + x + ')', '', '1')
                                    this.verificaSessao()
                                    this.loading = false
                                  },
                                  (r) => {
                                    this.verificaSessao()
                                    this.loading = false
                                  }
                                )
                              }
                            },
                            (x) => {
                              this.verificaSessao()
                              this.loading = false
                            }
                          )
                        }
                      )
                    },
                    (pm) => {}
                  )
                },
                (pm) => {}
              )
            },
            (np) => {}
          )
        },
        (pr) => {}
      )
    },
    ajuda: function() {
      this.$bvModal.show('ajuda')
    },
    remocaoProduto: function(p) {
      if (confirm('Tem certeza? Esta ação não pode ser desfeita!')) {
        var usoParams = {
          g: this.$session.get('grupo'),
          p: p
        }
        this.$http.post(this.URLApp + 'tabelas/removerProduto', usoParams).then(
          (rr) => {
            this.$swal(rr.body)
            this.verificaSessao()
          },
          (rr) => {
            console.log(rr)
            alert('Falha ao remover produto...')
          }
        )
      }
    }
  },
  components: {
    Loading,
    VueAutonumeric,
    HotTable
  }
}
</script>